.form-control {
    border-radius: 5px;
}

.centered {
    text-align: center;
}

.mandatory {
    font-weight: bold;
    font-size: 10pt;
}

#app-name {
    color: #288988;
}

#user-name {
    color: #288988;
}