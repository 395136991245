/*!
 * Step Form Wizard
 * Jiri Wasniowski - <ajoke3@gmail.com>
 * http://codecanyon.net/item/step-form-wizard/8591111
 * Version 2.0 - built July 16 2015
 */
/* clearfix */
clearfix:before,
.clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

.sf-left {
  float: left; }

.sf-right {
  float: right; }

.sf-step > .sf-step-el {
  border: none;
  margin: 0;
  padding: 0;
  display: block; }

.sf-wizard {
  position: relative; }
  .sf-wizard.sf-rtl {
    direction: rtl; }

.sf-hide-legend .sf-step-name, .sf-hide-buttons {
  display: none; }

.sf-nav {
  list-style: none;
  width: 100%;
  margin: 0px;
  padding: 0px;
  z-index: 4; }

.sf-viewport {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  transition: height 1s; }

.sf-viewport .sf-step-name {
  font-size: 20px;
  padding: 15px 0px;
  font-weight: bold;
  background: none;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px; }

.sf-btn.sf-btn-finish {
  margin: 0 0 0 15px; }

.sf-btn.sf-btn-disabled {
  cursor: default; }

.sf-nav-bottom {
  margin-top: 6px; }

.sf-nav-wrap {
  overflow: hidden;
  width: 100%;
  position: relative;
  left: 0; }

.sf-nav-top, .sf-nav-bottom {
  height: 40px;
  -ms-overflow-style: none;
  -ms-scroll-chaining: none;
  -ms-scroll-snap-type: mandatory;
  -ms-scroll-snap-points-x: snapInterval(0%, 100%); }

.sf-nav-top .sf-nav, .sf-nav-bottom .sf-nav {
  width: 9999px;
  position: absolute; }

.sf-nav-left {
  float: left;
  width: 20%;
  height: auto;
  overflow: hidden; }

.sf-nav-right {
  float: right;
  width: 20%;
  height: auto; }

.sf-nav-wrap.sf-nav-left li, .sf-nav-wrap.sf-nav-right li {
  float: none;
  margin-bottom: 10px; }

.sf-nav li {
  float: left;
  position: relative;
  margin-right: 10px; }

.sf-rtl .sf-nav-top .sf-nav-step, .sf-rtl .sf-nav-bottom .sf-nav-step {
  float: right; }
.sf-rtl .sf-nav-on-right {
  float: left;
  margin-right: 0; }

.sf-nav li.sf-nav-link:not(.sf-nav-step-disabled) {
  cursor: pointer; }

.sf-nav-right li, sf-nav-left li {
  padding-right: 10px; }

.sf-nav-on-left {
  width: 80%;
  margin-left: 20%; }

.sf-nav-on-right {
  width: 80%;
  margin-right: 20%; }

.sf-nav-right li {
  margin-right: 0;
  margin-left: 10px; }

.sf-step > .sf-step-el {
  overflow: auto;
  overflow-x: hidden; }

.sf-step-no-active {
  height: 1px; }
  .sf-step-no-active > .sf-step-el {
    display: none; }

li.sf-li-nonumber {
  text-align: center; }

.sf-li-nonumber .sf-nav-number {
  display: none; }

.sf-wizard .sf-btn, .sf-content .nocsript-sf-btn {
  padding: 0 35px; }

.sf-wizard > .sf-content {
  box-sizing: border-box; }

.sf-controls {
  z-index: 4; }
  .sf-controls input {
    margin: 0; }

.sf-viewport {
  width: 100%;
  position: relative;
  perspective: 1000px; }

.sf-fieldwrap {
  width: 100%;
  height: 100%;
  position: relative; }
  .sf-fieldwrap .sf-step {
    width: 100%;
    position: absolute;
    padding: 20px;
    display: none; }
  .sf-fieldwrap .sf-step.sf-step-front, .sf-fieldwrap .sf-step.sf-step-right, .sf-fieldwrap .sf-step.sf-step-left {
    display: block; }

.sf-3d-cube .sf-fieldwrap {
  transform-style: preserve-3d; }
  .sf-3d-cube .sf-fieldwrap .sf-step {
    background: #FFF; }

.sf-fade .sf-fieldwrap .sf-step.sf-step-right, .sf-fade .sf-fieldwrap .sf-step.sf-step-left {
  display: none; }

.sf-btn-next, .sf-btn-prev, .sf-btn-finish {
  display: none;
  cursor: pointer; }

.sf-nav-step-disabled {
  cursor: auto; }

.spinner {
  margin: 2px auto 0;
  width: 70px;
  text-align: center; }

.spinner > div {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #FFF;
  display: inline-block;
  margin-right: 2px;
  -webkit-animation: sf-bounce 1.2s infinite ease-in-out;
  animation: sf-bounce 1.2s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.spinner .ball-1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.spinner .ball-2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sf-bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }
@keyframes sf-bounce {
  0%, 80%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1); } }
@media screen, projection {
  .sf-wizard *, .sf-wizard *:after, .sf-wizard *:before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; } }
/* ******** THEMES *********/
.sf-t1 {
  font-family: sans-serif; }
  .sf-t1 .sf-nav-bottom {
    margin-top: 30px;
    height: 50px; }
  .sf-t1 .sf-nav-top {
    height: 80px;
    padding-bottom: 30px; }
  .sf-t1 .sf-nav-on-bottom .sf-controls {
    margin-bottom: 30px; }
  .sf-t1 .sf-nav-top li, .sf-t1 .sf-nav-bottom li {
    -webkit-transition: margin 200ms;
    -moz-transition: margin 200ms;
    -ms-transition: margin 200ms;
    -o-transition: margin 200ms;
    transition: margin 200ms; }
  .sf-t1 .sf-nav li {
    font-size: 16px;
    color: #FFF;
    background: #ffc85e;
    height: 50px;
    line-height: 50px;
    padding: 0 30px 0 50px; }
    .sf-t1 .sf-nav li.sf-li-nonumber {
      padding-left: 30px; }
  .sf-t1 .sf-nav-top li.sf-active, .sf-t1 .sf-nav-bottom li.sf-active {
    background: #feaa07; }
    .sf-t1 .sf-nav-top li.sf-active:after, .sf-t1 .sf-nav-bottom li.sf-active:after {
      border-color: transparent transparent transparent #feaa07; }
    .sf-t1 .sf-nav-top li.sf-active:before, .sf-t1 .sf-nav-bottom li.sf-active:before {
      border-color: #f99200 transparent #f99200 transparent; }
    .sf-t1 .sf-nav-top li.sf-active .sf-nav-number, .sf-t1 .sf-nav-bottom li.sf-active .sf-nav-number {
      background: #f99200; }
  .sf-t1.sf-nomob .sf-nav-top li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink), .sf-t1.sf-nomob .sf-nav-bottom li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink) {
    background: #feaa07; }
    .sf-t1.sf-nomob .sf-nav-top li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after, .sf-t1.sf-nomob .sf-nav-bottom li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after {
      border-color: transparent transparent transparent #feaa07; }
    .sf-t1.sf-nomob .sf-nav-top li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):before, .sf-t1.sf-nomob .sf-nav-bottom li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):before {
      border-color: #f99200 transparent #f99200 transparent; }
    .sf-t1.sf-nomob .sf-nav-top li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink) .sf-nav-number, .sf-t1.sf-nomob .sf-nav-bottom li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink) .sf-nav-number {
      background: #f99200; }
  .sf-t1 .sf-nav-top li.sf-li-nonumber.sf-active:before, .sf-t1 .sf-nav-bottom li.sf-li-nonumber.sf-active:before {
    border-color: #feaa07 transparent #feaa07 transparent; }
  .sf-t1.sf-nomob .sf-nav-top li.sf-li-nonumber.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):before, .sf-t1.sf-nomob .sf-nav-bottom li.sf-li-nonumber.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):before {
    border-color: #feaa07 transparent #feaa07 transparent; }
  .sf-t1 .sf-nav-left li.sf-active, .sf-t1 .sf-nav-right li.sf-active {
    background: #feaa07; }
    .sf-t1 .sf-nav-left li.sf-active:after, .sf-t1 .sf-nav-right li.sf-active:after {
      border-color: #f99200 transparent transparent transparent; }
    .sf-t1 .sf-nav-left li.sf-active:before, .sf-t1 .sf-nav-right li.sf-active:before {
      border-color: #FFF transparent transparent transparent; }
    .sf-t1 .sf-nav-left li.sf-active .sf-nav-number, .sf-t1 .sf-nav-right li.sf-active .sf-nav-number {
      background: #f99200; }
  .sf-t1.sf-nomob .sf-nav-left li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink), .sf-t1.sf-nomob .sf-nav-right li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink) {
    background: #feaa07; }
    .sf-t1.sf-nomob .sf-nav-left li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after, .sf-t1.sf-nomob .sf-nav-right li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after {
      border-color: #f99200 transparent transparent transparent; }
    .sf-t1.sf-nomob .sf-nav-left li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):before, .sf-t1.sf-nomob .sf-nav-right li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):before {
      border-color: #FFF transparent transparent transparent; }
    .sf-t1.sf-nomob .sf-nav-left li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink) .sf-nav-number, .sf-t1.sf-nomob .sf-nav-right li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink) .sf-nav-number {
      background: #f99200; }
  .sf-t1 .sf-nav-number {
    position: absolute;
    text-align: center;
    width: 37px;
    left: 0px;
    top: 0;
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    background: #ffd687; }
  .sf-t1 .sf-nav-left .sf-nav-number, .sf-t1 .sf-nav-right .sf-nav-number {
    width: 50px; }
  .sf-t1 .sf-nav-number-inner {
    display: block; }
  .sf-t1 .sf-li-number .sf-nav-subtext {
    padding-left: 30px; }
  .sf-t1 .sf-nav-subtext {
    display: block; }
  .sf-t1 .sf-nav-top .sf-nav-step:after, .sf-t1 .sf-nav-top.sf-btn:after, .sf-t1 .sf-nav-bottom .sf-nav-step:after, .sf-t1 .sf-nav-bottom.sf-btn:after, .sf-t1 .sf-nav-left .sf-nav-step:after, .sf-t1 .sf-nav-left.sf-btn:after, .sf-t1 .sf-nav-right .sf-nav-step:after, .sf-t1 .sf-nav-right.sf-btn:after, .sf-t1 .sf-btn .sf-nav-step:after, .sf-t1 .sf-btn.sf-btn:after {
    content: "";
    width: 1px;
    height: 1px;
    background: transparent;
    position: absolute;
    top: 0;
    right: -26px;
    border: 20px solid transparent;
    border-width: 25px 13px 25px 13px;
    border-left-color: #ffc85e;
    z-index: -1; }
  .sf-t1 .sf-nav-top .sf-nav-step:before, .sf-t1 .sf-nav-bottom .sf-nav-step:before, .sf-t1 .sf-nav-left .sf-nav-step:before, .sf-t1 .sf-nav-right .sf-nav-step:before, .sf-t1 .sf-btn .sf-nav-step:before {
    content: "";
    width: 1px;
    height: 1px;
    background: transparent;
    position: absolute;
    top: 0;
    left: -13px;
    border: 20px solid transparent;
    border-width: 25px 13px 25px 13px;
    border-top-color: #ffd687;
    border-bottom-color: #ffd687;
    z-index: -1; }
  .sf-t1 .sf-nav-top .sf-li-nonumber:before, .sf-t1 .sf-nav-bottom .sf-li-nonumber:before, .sf-t1 .sf-nav-left .sf-li-nonumber:before, .sf-t1 .sf-nav-right .sf-li-nonumber:before, .sf-t1 .sf-btn .sf-li-nonumber:before {
    border-top-color: #ffc85e;
    border-bottom-color: #ffc85e; }
  .sf-t1 .sf-nav-top .sf-nav-step, .sf-t1 .sf-nav-bottom .sf-nav-step {
    margin-right: 22px;
    padding-left: 37px; }
    .sf-t1 .sf-nav-top .sf-nav-step:first-child, .sf-t1 .sf-nav-bottom .sf-nav-step:first-child {
      padding-left: 50px; }
      .sf-t1 .sf-nav-top .sf-nav-step:first-child .sf-nav-number, .sf-t1 .sf-nav-bottom .sf-nav-step:first-child .sf-nav-number {
        width: 50px; }
    .sf-t1 .sf-nav-top .sf-nav-step.sf-li-nonumber:first-child, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-li-nonumber:first-child {
      padding-left: 30px; }
    .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled), .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled) {
      background: #feaa07;
      color: #FFF; }
      .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled):after, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled):after {
        border-color: transparent transparent transparent #feaa07; }
      .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled):before, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled):before {
        border-color: #F97200 transparent #F97200 transparent; }
      .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled) .sf-nav-number, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled) .sf-nav-number {
        background: #F97200; }
    .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-mark-step.sf-li-nonumber:not(.sf-active):not(.sf-nav-step-disabled), .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-mark-step.sf-li-nonumber:not(.sf-active):not(.sf-nav-step-disabled) {
      background: #F97200; }
      .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-mark-step.sf-li-nonumber:not(.sf-active):not(.sf-nav-step-disabled):before, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-mark-step.sf-li-nonumber:not(.sf-active):not(.sf-nav-step-disabled):before {
        border-color: #F97200 transparent #F97200 transparent; }
      .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-mark-step.sf-li-nonumber:not(.sf-active):not(.sf-nav-step-disabled):after, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-mark-step.sf-li-nonumber:not(.sf-active):not(.sf-nav-step-disabled):after {
        border-color: transparent transparent transparent #F97200; }
    .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-step-disabled, .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-unlink, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-step-disabled, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-unlink {
      background: #f3f3f3;
      color: #b8b8b8; }
      .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-step-disabled:after, .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-unlink:after, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-step-disabled:after, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-unlink:after {
        border-color: transparent transparent transparent #f3f3f3; }
      .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-step-disabled:before, .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-unlink:before, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-step-disabled:before, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-unlink:before {
        border-color: #e9e9e9 transparent #e9e9e9 transparent; }
      .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-step-disabled .sf-nav-number, .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-unlink .sf-nav-number, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-step-disabled .sf-nav-number, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-unlink .sf-nav-number {
        background: #e9e9e9; }
    .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-step-disabled.sf-li-nonumber:before, .sf-t1 .sf-nav-top .sf-nav-step.sf-nav-unlink.sf-li-nonumber:before, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-step-disabled.sf-li-nonumber:before, .sf-t1 .sf-nav-bottom .sf-nav-step.sf-nav-unlink.sf-li-nonumber:before {
      border-color: #f3f3f3 transparent #f3f3f3 transparent; }
  .sf-t1.sf-rtl .sf-li-number .sf-nav-subtext {
    padding-left: 0;
    padding-right: 30px; }
  .sf-t1.sf-rtl .sf-nav li {
    padding: 0 50px 0 30px; }
    .sf-t1.sf-rtl .sf-nav li.sf-li-nonumber {
      padding-right: 10px; }
  .sf-t1.sf-rtl .sf-nav-number {
    right: 0px; }
  .sf-t1.sf-rtl .sf-nav-top .sf-nav li.sf-li-nonumber, .sf-t1.sf-rtl .sf-nav-bottom .sf-nav li.sf-li-nonumber {
    padding-right: 30px; }
  .sf-t1.sf-rtl .sf-nav-top li.sf-active:after, .sf-t1.sf-rtl .sf-nav-bottom li.sf-active:after {
    border-color: transparent #feaa07 transparent transparent; }
  .sf-t1.sf-rtl .sf-nav-top .sf-nav-step:after, .sf-t1.sf-rtl .sf-nav-top.sf-btn:after, .sf-t1.sf-rtl .sf-nav-bottom .sf-nav-step:after, .sf-t1.sf-rtl .sf-nav-bottom.sf-btn:after, .sf-t1.sf-rtl .sf-btn .sf-nav-step:after, .sf-t1.sf-rtl .sf-btn.sf-btn:after {
    right: auto;
    left: -26px;
    border-right-color: #ffc85e;
    border-left-color: transparent; }
  .sf-t1.sf-rtl .sf-nav-top .sf-nav-step:before, .sf-t1.sf-rtl .sf-nav-bottom .sf-nav-step:before, .sf-t1.sf-rtl .sf-btn .sf-nav-step:before {
    left: auto;
    right: -13px; }
  .sf-t1.sf-rtl .sf-nav-top .sf-nav-step, .sf-t1.sf-rtl .sf-nav-bottom .sf-nav-step {
    margin-right: 0;
    margin-left: 22px;
    padding-left: 15px;
    padding-right: 37px; }
    .sf-t1.sf-rtl .sf-nav-top .sf-nav-step:first-child, .sf-t1.sf-rtl .sf-nav-bottom .sf-nav-step:first-child {
      padding-right: 50px; }
    .sf-t1.sf-rtl .sf-nav-top .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled), .sf-t1.sf-rtl .sf-nav-bottom .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled) {
      background: #feaa07; }
      .sf-t1.sf-rtl .sf-nav-top .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled):after, .sf-t1.sf-rtl .sf-nav-bottom .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled):after {
        border-color: transparent #feaa07 transparent transparent; }
    .sf-t1.sf-rtl .sf-nav-top .sf-nav-step.sf-nav-step-disabled:after, .sf-t1.sf-rtl .sf-nav-top .sf-nav-step.sf-nav-unlink:after, .sf-t1.sf-rtl .sf-nav-bottom .sf-nav-step.sf-nav-step-disabled:after, .sf-t1.sf-rtl .sf-nav-bottom .sf-nav-step.sf-nav-unlink:after {
      border-color: transparent #f3f3f3 transparent transparent; }
  .sf-t1.sf-rtl .sf-nav-left .sf-nav-step:after, .sf-t1.sf-rtl .sf-nav-right .sf-nav-step:after {
    left: auto;
    right: 0;
    border-color-right: transparent; }
  .sf-t1.sf-rtl .sf-nav-left .sf-nav-step:before, .sf-t1.sf-rtl .sf-nav-right .sf-nav-step:before {
    left: auto;
    right: 0; }
  .sf-t1.sf-rtl .sf-nav-left .sf-nav-step.sf-li-nonumber, .sf-t1.sf-rtl .sf-nav-right .sf-nav-step.sf-li-nonumber {
    padding: 0 30px; }
    .sf-t1.sf-rtl .sf-nav-left .sf-nav-step.sf-li-nonumber:after, .sf-t1.sf-rtl .sf-nav-right .sf-nav-step.sf-li-nonumber:after {
      border-top-color: #ffc85e;
      left: auto;
      right: 50%;
      margin-right: -25px; }
    .sf-t1.sf-rtl .sf-nav-left .sf-nav-step.sf-li-nonumber:before, .sf-t1.sf-rtl .sf-nav-right .sf-nav-step.sf-li-nonumber:before {
      left: auto;
      right: 50%;
      margin-right: -25px; }
  .sf-t1.sf-rtl.sf-nomob .sf-nav-top li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after, .sf-t1.sf-rtl.sf-nomob .sf-nav-bottom li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after {
    border-color: transparent #feaa07 transparent transparent; }
  .sf-t1.sf-rtl.sf-nomob .sf-nav-top .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled).sf-li-nonumber:not(.sf-active):not(:hover), .sf-t1.sf-rtl.sf-nomob .sf-nav-bottom .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled).sf-li-nonumber:not(.sf-active):not(:hover) {
    background: #F97200; }
    .sf-t1.sf-rtl.sf-nomob .sf-nav-top .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled).sf-li-nonumber:not(.sf-active):not(:hover):before, .sf-t1.sf-rtl.sf-nomob .sf-nav-bottom .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled).sf-li-nonumber:not(.sf-active):not(:hover):before {
      border-color: #F97200 transparent #F97200 transparent; }
    .sf-t1.sf-rtl.sf-nomob .sf-nav-top .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled).sf-li-nonumber:not(.sf-active):not(:hover):after, .sf-t1.sf-rtl.sf-nomob .sf-nav-bottom .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled).sf-li-nonumber:not(.sf-active):not(:hover):after {
      border-color: transparent #F97200 transparent transparent; }
  .sf-t1.sf-rtl.sf-nomob .sf-nav-left .sf-nav-step.sf-li-nonumber:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after, .sf-t1.sf-rtl.sf-nomob .sf-nav-right .sf-nav-step.sf-li-nonumber:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after {
    border-top-color: #feaa07; }
  .sf-t1 .sf-nav-left .sf-nav-number, .sf-t1 .sf-nav-right .sf-nav-number {
    height: 50px;
    line-height: 65px; }
  .sf-t1 .sf-nav-left .sf-nav-step, .sf-t1 .sf-nav-right .sf-nav-step {
    margin-bottom: 15px; }
    .sf-t1 .sf-nav-left .sf-nav-step:after, .sf-t1 .sf-nav-right .sf-nav-step:after {
      top: auto;
      bottom: -26px;
      right: auto;
      left: 0;
      border: 20px solid transparent;
      border-width: 13px 25px 13px 25px;
      border-top-color: #ffd687;
      z-index: -1; }
    .sf-t1 .sf-nav-left .sf-nav-step:before, .sf-t1 .sf-nav-right .sf-nav-step:before {
      background: transparent;
      top: 0;
      left: 0;
      border: 20px solid transparent;
      border-width: 13px 25px 13px 25px;
      border-top-color: #FFF;
      z-index: 1; }
    .sf-t1 .sf-nav-left .sf-nav-step.sf-li-nonumber, .sf-t1 .sf-nav-right .sf-nav-step.sf-li-nonumber {
      line-height: 57px; }
      .sf-t1 .sf-nav-left .sf-nav-step.sf-li-nonumber:after, .sf-t1 .sf-nav-right .sf-nav-step.sf-li-nonumber:after {
        border-top-color: #ffc85e;
        left: 50%;
        margin-left: -25px; }
      .sf-t1 .sf-nav-left .sf-nav-step.sf-li-nonumber:before, .sf-t1 .sf-nav-right .sf-nav-step.sf-li-nonumber:before {
        left: 50%;
        margin-left: -25px; }
    .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled), .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled) {
      background: #feaa07;
      color: #FFF; }
      .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled):after, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled):after {
        border-color: #F97200 transparent transparent transparent; }
      .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled):before, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled):before {
        border-color: #FFF transparent transparent transparent; }
      .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled) .sf-nav-number, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled) .sf-nav-number {
        background: #F97200; }
      .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled).sf-li-nonumber, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-mark-step:not(.sf-active):not(.sf-nav-step-disabled).sf-li-nonumber {
        background: #F97200; }
    .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-step-disabled, .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-unlink, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-step-disabled, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-unlink {
      background: #f3f3f3;
      color: #b8b8b8; }
      .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-step-disabled:after, .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-unlink:after, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-step-disabled:after, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-unlink:after {
        border-color: #e9e9e9 transparent transparent transparent; }
      .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-step-disabled:before, .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-unlink:before, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-step-disabled:before, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-unlink:before {
        border-color: #FFF transparent transparent transparent; }
      .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-step-disabled .sf-nav-number, .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-unlink .sf-nav-number, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-step-disabled .sf-nav-number, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-unlink .sf-nav-number {
        background: #e9e9e9; }
      .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-step-disabled.sf-li-nonumber:after, .sf-t1 .sf-nav-left .sf-nav-step.sf-nav-unlink.sf-li-nonumber:after, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-step-disabled.sf-li-nonumber:after, .sf-t1 .sf-nav-right .sf-nav-step.sf-nav-unlink.sf-li-nonumber:after {
        border-color: #f3f3f3 transparent transparent transparent; }
  .sf-t1.sf-nomob .sf-nav-left .sf-nav-step.sf-li-nonumber.sf-active:after, .sf-t1.sf-nomob .sf-nav-left .sf-nav-step.sf-li-nonumber:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after, .sf-t1.sf-nomob .sf-nav-right .sf-nav-step.sf-li-nonumber.sf-active:after, .sf-t1.sf-nomob .sf-nav-right .sf-nav-step.sf-li-nonumber:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after {
    border-top-color: #feaa07; }
  .sf-t1 .sf-btn, .sf-t1 .sf-content .nocsript-sf-btn {
    height: 50px;
    line-height: 50px;
    padding: 0 25px;
    border-radius: 3px;
    border: none;
    color: #FFF;
    text-decoration: none;
    background-color: #feaa07;
    position: relative; }
    .sf-t1 .sf-btn:after, .sf-t1 .sf-content .nocsript-sf-btn:after {
      border-left: 13px solid #feaa07; }
  .sf-t1 .nocsript-sf-btn:hover {
    background-color: #f99200; }
  .sf-t1 .sf-btn.sf-btn-next {
    margin-right: 13px; }
    .sf-t1 .sf-btn.sf-btn-next:after {
      border-left-color: #feaa07; }
  .sf-t1 .sf-btn.sf-btn-prev, .sf-t1.sf-rtl .sf-btn.sf-btn-next {
    margin-left: 13px; }
    .sf-t1 .sf-btn.sf-btn-prev:after, .sf-t1.sf-rtl .sf-btn.sf-btn-next:after {
      right: auto;
      left: -26px;
      border: 20px solid transparent;
      border-width: 25px 13px 25px 13px;
      border-right-color: #feaa07; }
  .sf-t1.sf-rtl .sf-btn.sf-btn-prev {
    margin-left: 0;
    margin-right: 13px; }
    .sf-t1.sf-rtl .sf-btn.sf-btn-prev:after {
      left: auto;
      right: -26px;
      border: 20px solid transparent;
      border-width: 25px 13px 25px 13px;
      border-left-color: #feaa07; }
  .sf-t1.sf-rtl .sf-btn.sf-btn-next {
    margin-left: 13px; }
    .sf-t1.sf-rtl .sf-btn.sf-btn-next:after {
      right: auto;
      left: -26px;
      border: 20px solid transparent;
      border-width: 25px 13px 25px 13px;
      border-right-color: #feaa07; }
  .sf-t1.sf-nomob .sf-btn:hover, .sf-t1.sf-mob .sf-btn:active {
    background-color: #f99200; }
  .sf-t1.sf-nomob .sf-btn.sf-btn-next:hover:after, .sf-t1.sf-mob .sf-btn.sf-btn-next:active:after {
    border-left-color: #f99200; }
  .sf-t1.sf-nomob .sf-btn.sf-btn-prev:hover:after, .sf-t1.sf-mob .sf-btn.sf-btn-prev:active:after {
    border-right-color: #f99200; }
  .sf-t1.sf-rtl.sf-nomob .sf-btn.sf-btn-prev:hover:after, .sf-t1.sf-rtl.sf-mob .sf-btn.sf-btn-prev:active:after {
    border-left: 13px solid #f99200;
    border-right: 13px solid transparent; }
  .sf-t1.sf-rtl.sf-nomob .sf-btn.sf-btn-next:hover:after, .sf-t1.sf-rtl.sf-mob .sf-btn.sf-btn-next:active:after {
    border-right: 13px solid #f99200;
    border-left: 13px solid transparent; }
  .sf-t1 .sf-btn-disabled, .sf-t1.sf-wizard.sf-nomob .sf-btn-disabled:hover, .sf-t1.sf-wizard.sf-mob .sf-btn-disabled:active {
    background: #f3f3f3;
    color: #b8b8b8; }
    .sf-t1 .sf-btn-disabled.sf-btn.sf-btn-next:after, .sf-t1.sf-wizard.sf-nomob .sf-btn-disabled:hover.sf-btn.sf-btn-next:after, .sf-t1.sf-wizard.sf-mob .sf-btn-disabled:active.sf-btn.sf-btn-next:after {
      border-color: transparent transparent transparent #f3f3f3; }
    .sf-t1 .sf-btn-disabled.sf-btn.sf-btn-prev:after, .sf-t1.sf-wizard.sf-nomob .sf-btn-disabled:hover.sf-btn.sf-btn-prev:after, .sf-t1.sf-wizard.sf-mob .sf-btn-disabled:active.sf-btn.sf-btn-prev:after {
      border-color: transparent #f3f3f3 transparent transparent; }
  .sf-t1.sf-rtl .sf-btn-disabled.sf-btn.sf-btn-next:after, .sf-t1.sf-rtl.sf-wizard.sf-nomob .sf-btn-disabled:hover.sf-btn.sf-btn-next:after, .sf-t1.sf-rtl.sf-wizard.sf-mob .sf-btn-disabled:active.sf-btn.sf-btn-next:after {
    border-color: transparent #f3f3f3 transparent transparent; }
  .sf-t1.sf-rtl .sf-btn-disabled.sf-btn.sf-btn-prev:after, .sf-t1.sf-rtl.sf-wizard.sf-nomob .sf-btn-disabled:hover.sf-btn.sf-btn-prev:after, .sf-t1.sf-rtl.sf-wizard.sf-mob .sf-btn-disabled:active.sf-btn.sf-btn-prev:after {
    border-color: transparent transparent transparent #f3f3f3; }
  .sf-t1.sf-fade .sf-nav-on-top .sf-viewport, .sf-t1.sf-fade .sf-nav-on-bottom .sf-viewport, .sf-t1.sf-slide .sf-nav-on-top .sf-viewport, .sf-t1.sf-slide .sf-nav-on-bottom .sf-viewport, .sf-t1.sf-none .sf-nav-on-top .sf-viewport, .sf-t1.sf-none .sf-nav-on-bottom .sf-viewport {
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada; }
  .sf-t1 .sf-nav-on-left {
    border-left: 1px solid #dadada;
    padding-left: 10px; }
    .sf-t1 .sf-nav-on-left .sf-controls {
      padding: 0 20px; }
  .sf-t1 .sf-nav-on-right {
    border-right: 1px solid #dadada;
    padding-right: 10px; }
    .sf-t1 .sf-nav-on-right .sf-controls {
      padding: 0 20px; }
  .sf-t1 .sf-nav-left li {
    margin-right: 30px; }
  .sf-t1 .sf-nav-right li {
    margin-left: 30px; }
  .sf-t1 .sf-step .sf-step-name {
    padding-top: 5px; }
  .sf-t1.sf-3d-cube .sf-step {
    background: #FFF; }
  .sf-t1.sf-3d-cube .sf-nav-on-top .sf-step {
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada; }

@media all and (max-width: 1200px) {
  .sf-t1 .sf-nav li {
    padding: 0 10px 0 50px; }
    .sf-t1 .sf-nav li.sf-li-nonumber, .sf-t1 .sf-nav li.sf-nav-step.sf-li-nonumber:first-child {
      padding-left: 10px; }
  .sf-t1 .sf-li-number .sf-nav-subtext {
    padding-left: 10px; }
  .sf-t1.sf-rtl .sf-nav-top .sf-nav li.sf-li-nonumber, .sf-t1.sf-rtl .sf-nav-bottom .sf-nav li.sf-li-nonumber {
    padding-right: 10px; } }
@media all and (max-width: 992px) {
  .sf-t1 .sf-nav-smmob .sf-nav li {
    padding: 0 0px 0 10px; }
    .sf-t1 .sf-nav-smmob .sf-nav li.sf-li-nonumber {
      padding-right: 10px; }
  .sf-t1 .sf-nav-smmob .sf-nav li > span {
    display: none; }
  .sf-t1 .sf-nav-smmob.sf-nav-left .sf-nav li, .sf-t1 .sf-nav-smmob.sf-nav-right .sf-nav li {
    margin-right: 55px; }
  .sf-t1 .sf-nav-smmob.sf-nav-left .sf-nav li.sf-li-nonumber, .sf-t1 .sf-nav-smmob.sf-nav-right .sf-nav li.sf-li-nonumber {
    padding: 0 40px 0 10px;
    margin-right: 15px; }
  .sf-t1 .sf-nav-smmob.sf-nav-left.sf-nav-right .sf-nav li, .sf-t1 .sf-nav-smmob.sf-nav-right.sf-nav-right .sf-nav li {
    margin-left: 15px;
    margin-right: 45px; }
  .sf-t1 .sf-nav-smmob.sf-nav-left.sf-nav-right .sf-nav li.sf-li-nonumber, .sf-t1 .sf-nav-smmob.sf-nav-right.sf-nav-right .sf-nav li.sf-li-nonumber {
    margin-right: 0px; }
  .sf-t1 .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step, .sf-t1 .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step {
    width: 35px; }
    .sf-t1 .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step:after, .sf-t1 .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step:after {
      border-color: transparent transparent transparent #ffd687; }
    .sf-t1 .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-active:after, .sf-t1 .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-active:after {
      border-color: transparent transparent transparent #f99200; }
    .sf-t1 .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled):after, .sf-t1 .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled):after {
      border-color: transparent transparent transparent #F97200; }
    .sf-t1 .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-nav-unlink:after, .sf-t1 .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-nav-step-disabled:after, .sf-t1 .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-nav-unlink:after, .sf-t1 .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-nav-step-disabled:after {
      border-color: transparent transparent transparent #e9e9e9; }
    .sf-t1 .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-li-nonumber:first-child, .sf-t1 .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-li-nonumber:first-child {
      padding-left: 30px; }
    .sf-t1 .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-li-nonumber:after, .sf-t1 .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-li-nonumber:after {
      border-color: transparent transparent transparent #ffc85e; }
    .sf-t1 .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-li-nonumber.sf-active:after, .sf-t1 .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-li-nonumber.sf-active:after {
      border-color: transparent transparent transparent #feaa07; }
    .sf-t1 .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-li-nonumber.sf-nav-unlink:after, .sf-t1 .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-li-nonumber.sf-nav-step-disabled:after, .sf-t1 .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-li-nonumber.sf-nav-unlink:after, .sf-t1 .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-li-nonumber.sf-nav-step-disabled:after {
      border-color: transparent transparent transparent #f3f3f3; }
  .sf-t1.sf-nomob .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after, .sf-t1.sf-nomob .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after {
    border-color: transparent transparent transparent #f99200; }
  .sf-t1.sf-nomob .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-li-nonumber.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after, .sf-t1.sf-nomob .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-li-nonumber.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after {
    border-color: transparent transparent transparent #feaa07; }
  .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step {
    width: 35px;
    padding-right: 22px; }
    .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step:first-child, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step:first-child {
      padding-right: 35px; }
    .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step:after, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step:after {
      border-color: transparent #ffd687 transparent transparent; }
    .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-active:after, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-active:after {
      border-color: transparent #f99200 transparent transparent; }
    .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled):after, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-nav-mark-step:not(.sf-nav-step-disabled):after {
      border-color: transparent #F97200 transparent transparent; }
    .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-nav-unlink:after, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-nav-step-disabled:after, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-nav-unlink:after, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-nav-step-disabled:after {
      border-color: transparent #e9e9e9 transparent transparent; }
    .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-li-nonumber:first-child, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-li-nonumber:first-child {
      padding-right: 30px; }
    .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-li-nonumber:after, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-li-nonumber:after {
      border-color: transparent #ffc85e transparent transparent; }
    .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-li-nonumber.sf-active:after, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-li-nonumber.sf-active:after {
      border-color: transparent #feaa07 transparent transparent; }
    .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-li-nonumber.sf-nav-unlink:after, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-li-nonumber.sf-nav-step-disabled:after, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-li-nonumber.sf-nav-unlink:after, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-li-nonumber.sf-nav-step-disabled:after {
      border-color: transparent #f3f3f3 transparent transparent; }
  .sf-t1.sf-rtl .sf-nav-left .sf-li-nonumber.sf-nav-step, .sf-t1.sf-rtl .sf-nav-right .sf-li-nonumber.sf-nav-step {
    padding: 0 10px; }
  .sf-t1.sf-rtl.sf-nomob .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after, .sf-t1.sf-rtl.sf-nomob .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after {
    border-color: transparent #f99200 transparent transparent; }
  .sf-t1.sf-rtl.sf-nomob .sf-nav-smmob.sf-nav-top .sf-nav .sf-nav-step.sf-li-nonumber.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after, .sf-t1.sf-rtl.sf-nomob .sf-nav-smmob.sf-nav-bottom .sf-nav .sf-nav-step.sf-li-nonumber.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink):after {
    border-color: transparent #feaa07 transparent transparent; }
  .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-left .sf-nav li, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-right .sf-nav li {
    padding: 0 40px 0 10px;
    margin-right: 0; }
  .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-left.sf-nav-left .sf-nav li, .sf-t1.sf-rtl .sf-nav-smmob.sf-nav-right.sf-nav-left .sf-nav li {
    margin-right: 15px; }
  .sf-t1.sf-wizard .sf-btn {
    padding: 0 10px; } }
.sf-t2 {
  font-family: sans-serif; }
  .sf-t2 .sf-nav-bottom {
    margin-top: 10px;
    height: 60px; }
  .sf-t2 .sf-nav-top {
    height: 83px; }
  .sf-t2 .sf-nav-top li, .sf-t2 .sf-nav-bottom li {
    -webkit-transition: margin 200ms;
    -moz-transition: margin 200ms;
    -ms-transition: margin 200ms;
    -o-transition: margin 200ms;
    transition: margin 200ms; }
  .sf-t2 .sf-nav li {
    font-size: 16px;
    color: #FFF;
    background: #283d50;
    height: 60px;
    line-height: 60px;
    padding: 0 45px;
    border-radius: 3px; }
  .sf-t2:not(.sf-rtl) .sf-nav-top .sf-nav li:last-child, .sf-t2:not(.sf-rtl) .sf-nav-bottom .sf-nav li:last-child {
    margin-right: 0; }
  .sf-t2 .sf-nav li.sf-nav-mark-step:not(.sf-nav-step-disabled):not(.sf-active) {
    background: #5C3665; }
  .sf-t2 .sf-nav li.sf-active, .sf-t2.sf-nomob .sf-nav li.sf-nav-link:not(.sf-nav-step-disabled):not(.sf-nav-unlink):hover {
    background: #14b5c8; }
  .sf-t2 .sf-nav-top li.sf-active {
    margin-top: 6px; }
  .sf-t2 .sf-nav-number {
    position: absolute;
    left: 12px;
    top: 0;
    font-size: 96px;
    font-weight: 700;
    overflow: hidden; }
  .sf-t2 .sf-nav-number-inner {
    display: block; }
  .sf-t2 .sf-li-number .sf-nav-subtext {
    padding-left: 35px; }
  .sf-t2 .sf-li-number.sf-li-numbers-two .sf-nav-subtext {
    padding-left: 90px; }
  .sf-t2 .sf-nav-subtext {
    display: block; }
  .sf-t2 .sf-nav-top .sf-nav-step.sf-active:after {
    content: "";
    width: 5px;
    height: 5px;
    background: transparent;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -12px;
    border: 12px solid black;
    border-color: transparent #14b5c8 #14b5c8 transparent;
    z-index: -1; }
  .sf-t2.sf-wizard .sf-btn, .sf-t2 .sf-content .nocsript-sf-btn {
    height: 45px;
    line-height: 45px;
    padding: 0 25px;
    border-radius: 3px;
    border: none;
    color: #FFF;
    text-decoration: none;
    background-color: #283d50; }
  .sf-t2.sf-wizard.sf-nomob .sf-btn:hover, .sf-t2.sf-wizard.sf-mob .sf-btn:active, .sf-t2 .nocsript-sf-btn:hover {
    background-color: #14b5c8; }
  .sf-t2.sf-wizard .sf-btn.sf-btn-next, .sf-t2.sf-wizard.sf-rtl .sf-btn.sf-btn-prev {
    background: url("../img/t1-arr-right.png") no-repeat right 15px #283d50;
    padding-right: 50px; }
  .sf-t2.sf-wizard .sf-btn.sf-btn-prev, .sf-t2.sf-wizard.sf-rtl .sf-btn.sf-btn-next {
    background: url("../img/t1-arr-left.png") no-repeat left 15px #283d50;
    padding-left: 50px; }
  .sf-t2.sf-wizard.sf-nomob .sf-btn.sf-btn-next:hover, .sf-t2.sf-wizard.sf-mob .sf-btn.sf-btn-next:active, .sf-t2.sf-wizard.sf-nomob.sf-rtl .sf-btn.sf-btn-prev:hover, .sf-t2.sf-wizard.sf-mob.sf-rtl .sf-btn.sf-btn-prev:active {
    background-position: right -35px; }
  .sf-t2.sf-wizard.sf-nomob .sf-btn.sf-btn-prev:hover, .sf-t2.sf-wizard.sf-mob .sf-btn.sf-btn-prev:active, .sf-t2.sf-wizard.sf-nomob.sf-rtl .sf-btn.sf-btn-next:hover, .sf-t2.sf-wizard.sf-mob.sf-rtl .sf-btn.sf-btn-next:hover {
    background-position: left -35px; }
  .sf-t2 .sf-nav-step.sf-nav-step-disabled, .sf-t2 .sf-nav-step.sf-nav-unlink, .sf-t2.sf-wizard .sf-btn.sf-btn-disabled, .sf-t2.sf-rtl.sf-wizard .sf-btn.sf-btn-disabled {
    background: #bbc1c7; }
  .sf-t2.sf-wizard > .sf-content {
    border-radius: 3px; }
  .sf-t2 .sf-step .sf-step-name {
    padding-top: 5px; }
  .sf-t2:not(.sf-3d-cube) .sf-nav-on-left .sf-controls, .sf-t2:not(.sf-3d-cube) .sf-nav-on-right .sf-controls {
    padding: 0 20px; }
  .sf-t2 .sf-nav-on-bottom .sf-controls {
    margin-bottom: 20px; }
  .sf-t2.sf-3d-cube .sf-step {
    background: #FFF; }
  .sf-t2.sf-3d-cube .sf-fieldwrap .sf-step {
    padding: 20px;
    border-radius: 3px;
    border: 1px solid #e7e7e7; }
  .sf-t2.sf-none .sf-viewport, .sf-t2.sf-fade .sf-viewport, .sf-t2.sf-slide .sf-viewport {
    border-radius: 3px;
    border: 1px solid #e7e7e7; }
    .sf-t2.sf-none .sf-viewport .sf-step, .sf-t2.sf-fade .sf-viewport .sf-step, .sf-t2.sf-slide .sf-viewport .sf-step {
      padding: 20px; }

@media all and (max-width: 1200px) {
  .sf-t2 .sf-nav li {
    padding: 0 10px; }
  .sf-t2 .sf-li-number .sf-nav-subtext {
    padding-left: 65px; } }
@media all and (max-width: 992px) {
  .sf-t2 .sf-nav-smmob .sf-nav li {
    padding: 0 31px; }
  .sf-t2 .sf-nav-smmob .sf-nav li > span {
    display: none; }
  .sf-t2 .sf-nav-smmob .sf-nav-number {
    left: 4px; }
  .sf-t2 .sf-nav-smmob .sf-nav li.sf-active:after {
    border: none; }
  .sf-t2 .sf-nav-smmob.sf-nav-top .sf-nav-step.sf-active:after {
    display: none; }
  .sf-t2.sf-wizard .sf-btn {
    padding: 0 10px; } }
.sf-t3 {
  font-family: sans-serif; }
  .sf-t3 .sf-nav-bottom {
    margin-top: 20px; }
  .sf-t3 .sf-nav-top, .sf-t3 .sf-nav-bottom {
    height: 80px; }
  .sf-t3 .sf-nonumbers .sf-nav-top {
    height: 65px; }
  .sf-t3 .sf-nonumbers .sf-nav-bottom {
    height: 42px; }
  .sf-t3 .sf-controls {
    height: 50px; }
  .sf-t3 .sf-nav {
    margin-bottom: 25px; }
  .sf-t3 .sf-nav li {
    font-size: 14px;
    background: #283d50;
    color: #FFF;
    height: 52px;
    line-height: 42px;
    padding: 0 40px;
    text-align: center;
    border-radius: 3px;
    margin-right: 30px;
    font-weight: 600; }
  .sf-t3 .sf-nav li.sf-li-nonumber {
    height: 42px; }
  .sf-t3 .sf-nav-left .sf-nav li.sf-li-nonumber, .sf-t3 .sf-nav-right .sf-nav li.sf-li-nonumber {
    margin-bottom: 32px; }
  .sf-t3 .sf-nav-top li.sf-li-nonumber:after, .sf-t3 .sf-nav-bottom li.sf-li-nonumber:after {
    top: -8px; }
  .sf-t3 .sf-nav-right li.sf-li-nonumber:after, .sf-t3 .sf-nav-left li.sf-li-nonumber:after {
    bottom: -47px; }
  .sf-t3 .sf-nav li.sf-nav-step.sf-nav-step-disabled {
    color: #adb3b8;
    background: #f2f2f2; }
    .sf-t3 .sf-nav li.sf-nav-step.sf-nav-step-disabled:hover {
      cursor: auto; }
  .sf-t3 .sf-nav li:after {
    content: "";
    position: absolute;
    right: -30px;
    top: 0px;
    background: url("../img/t2-nav-arr.png") no-repeat center center transparent;
    width: 30px;
    height: 54px; }
  .sf-t3 .sf-nav li:last-child:after {
    display: none; }
  .sf-t3.sf-rtl .sf-nav-top li:after, .sf-t3.sf-rtl .sf-nav-bottom li:after {
    right: auto;
    left: -30px;
    background: url("../img/t2-nav-arr-rtl.png") no-repeat center center transparent; }
  .sf-t3 .sf-nav-right li {
    margin-right: 0px;
    margin-left: 30px; }
  .sf-t3 .sf-nav-right li:after, .sf-t3 .sf-nav-left li:after {
    background: url("../img/t2-nav-arr-down.png") no-repeat center center transparent;
    right: auto;
    left: 50%;
    top: auto;
    bottom: -55px;
    margin-left: -15px; }
  .sf-t3 .sf-nav li.sf-active, .sf-t3 .sf-nomob .sf-nav li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink) {
    background: #283d50;
    color: #FFF; }
    .sf-t3 .sf-nav li.sf-active .sf-nav-number, .sf-t3 .sf-nomob .sf-nav li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink) .sf-nav-number {
      background: #f56b46; }
  .sf-t3 .sf-nav li.sf-nav-mark-step {
    background: #283d50; }
    .sf-t3 .sf-nav li.sf-nav-mark-step .sf-nav-number {
      background: #36B28C; }
  .sf-t3 .sf-nav li.sf-nav-step-disabled, .sf-t3 .sf-nav li.sf-nav-unlink {
    background: #f2f2f9;
    color: #adb3b8; }
  .sf-t3 .sf-nav-top li.sf-active {
    margin-top: 0px; }
  .sf-t3 .sf-nav-wrap.sf-nav-left li, .sf-t3 .sf-nav-wrap.sf-nav-right li {
    margin-bottom: 40px; }
  .sf-t3 .sf-nav-number {
    position: absolute;
    left: 50%;
    bottom: -15px;
    margin-left: -15px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    background: #c7c7c7;
    border-radius: 50px;
    border: 3px solid #FFF;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #FFF; }
  .sf-t3 .sf-nav li.sf-active .sf-nav-number {
    background: #f56b46; }
  .sf-t3 .sf-li-number .sf-nav-subtext {
    padding-left: 0px; }
  .sf-t3.sf-wizard .sf-btn, .sf-t3 .sf-content .nocsript-sf-btn {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    border-radius: 3px;
    border: none;
    color: #FFF;
    text-decoration: none;
    background: #283d50;
    position: relative; }
  .sf-t3.sf-wizard .sf-btn:after {
    content: "";
    position: absolute;
    right: -15px;
    top: 10px;
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    background: url("../img/t2-arr-right.png") no-repeat center center #f56b46;
    border-radius: 50px;
    border: 3px solid #FFF;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #FFF; }
  .sf-t3.sf-wizard.sf-rtl .sf-btn:after {
    right: auto;
    left: -15px;
    margin: 0;
    background: url("../img/t2-arr-left.png") no-repeat center center #f56b46; }
  .sf-t3.sf-wizard .sf-btn.sf-btn-disabled {
    background: #f2f2f9;
    color: #adb3b8; }
    .sf-t3.sf-wizard .sf-btn.sf-btn-disabled:after {
      background-color: #c7c7c7; }
  .sf-t3.sf-wizard .sf-btn.sf-btn-next {
    padding-right: 35px; }
  .sf-t3 .sf-controls {
    padding: 0 12px; }
  .sf-t3.sf-wizard .sf-btn.sf-btn-prev {
    padding-left: 35px; }
  .sf-t3.sf-wizard .sf-btn.sf-btn-prev:after {
    right: auto;
    left: -15px;
    background-image: url("../img/t2-arr-left.png"); }
  .sf-t3.sf-wizard.sf-rtl .sf-btn.sf-btn-prev:after {
    left: auto;
    right: -15px;
    background-image: url("../img/t2-arr-right.png"); }
  .sf-t3.sf-wizard .sf-btn.sf-btn-next:hover {
    background-position: right -35px; }
  .sf-t3.sf-wizard .sf-btn.sf-btn-prev:hover {
    background-position: left -35px; }
  .sf-t3 .sf-nav-on-bottom .sf-controls {
    margin-bottom: 20px; }
  .sf-t3.sf-3d-cube .sf-step {
    background: #FFF; }
  .sf-t3.sf-3d-cube .sf-fieldwrap .sf-step {
    padding: 15px;
    border-radius: 3px;
    border: 1px solid #e7e7e7; }
  .sf-t3.sf-none .sf-viewport, .sf-t3.sf-fade .sf-viewport, .sf-t3.sf-slide .sf-viewport {
    border-radius: 3px;
    border: 1px solid #e7e7e7; }
    .sf-t3.sf-none .sf-viewport .sf-step, .sf-t3.sf-fade .sf-viewport .sf-step, .sf-t3.sf-slide .sf-viewport .sf-step {
      padding: 15px; }

@media all and (max-width: 1200px) {
  .sf-t3 .sf-nav li {
    padding: 0 10px; }
  .sf-t3.sf-rtl .sf-controls .sf-btn {
    padding: 0 10px; }
  .sf-t3.sf-rtl .sf-controls .sf-btn-next {
    padding-left: 20px; }
  .sf-t3.sf-rtl .sf-controls .sf-btn-prev {
    padding-right: 20px; } }
@media all and (max-width: 992px) {
  .sf-t3 .sf-nav-smmob .sf-nav li {
    padding: 0px;
    width: 42px;
    height: 42px; }
  .sf-t3 .sf-nav-smmob .sf-nav li .sf-nav-subtext {
    display: none; }
  .sf-t3 .sf-nav-smmob.sf-nav-left li {
    margin-right: 50px; }
  .sf-t3 .sf-nav-smmob.sf-nav-right li {
    margin-left: 15px;
    margin-right: 40px; }
  .sf-t3.sf-rtl .sf-nav-smmob.sf-nav-left li {
    margin-right: 15px;
    margin-left: 40px; }
  .sf-t3.sf-rtl .sf-nav-smmob.sf-nav-right li {
    margin-left: 50px;
    margin-right: 0; }
  .sf-t3 .sf-nav-top .sf-li-number:after {
    height: 42px; }
  .sf-t3 .sf-nav-left li {
    margin-right: 15px; }
  .sf-t3 .sf-nav-right li {
    margin-left: 15px; }
  .sf-t3.sf-wizard .sf-btn {
    padding: 0 10px; }
  .sf-t3.sf-wizard:not(.sf-rtl) .sf-btn.sf-btn-next {
    padding-right: 22px; }
  .sf-t3.sf-wizard:not(.sf-rtl) .sf-btn.sf-btn-prev {
    padding-left: 22px; } }
.sf-t4 {
  font-family: sans-serif; }
  .sf-t4 .sf-nav-top, .sf-t4 .sf-nav-bottom {
    height: 80px; }
  .sf-t4.sf-nonumbers .sf-nav-top, .sf-t4.sf-nonumbers .sf-nav-bottom {
    height: 50px; }
  .sf-t4 .sf-nav-top {
    padding-bottom: 20px; }
  .sf-t4 .sf-nav {
    margin-bottom: 25px; }
  .sf-t4 .sf-nav li {
    font-size: 13px;
    color: #7a7a7a;
    height: 60px;
    line-height: 40px;
    text-align: left;
    margin-right: 0px;
    padding: 30px 40px 0 0px;
    font-weight: 400; }
  .sf-t4.sf-rtl .sf-nav li {
    padding: 30px 0 0 40px; }
  .sf-t4 .sf-nav li.sf-active, .sf-t4 .sf-nomob .sf-nav li.sf-nav-link:hover:not(.sf-nav-step-disabled):not(.sf-nav-unlink),
  .sf-t4 .sf-mob .sf-nav li.sf-nav-link:active:not(.sf-nav-step-disabled):not(.sf-nav-unlink) {
    color: #b21948; }
  .sf-t4 .sf-nav-top li, .sf-t4 .sf-nav-bottom li {
    background: url("../img/t2-nav-bg.png") repeat-x left 15px transparent; }
  .sf-t4 .sf-nav-top li:last-child, .sf-t4 .sf-nav-bottom li:last-child {
    background: none; }
  .sf-t4 .sf-nav li.sf-li-nonumber {
    background: none;
    height: 30px;
    padding-top: 0;
    line-height: 30px; }
  .sf-t4 .sf-nav-top li.sf-active {
    margin-top: 0px; }
  .sf-t4.sf-fade .sf-nav-on-top .sf-viewport, .sf-t4.sf-fade .sf-nav-on-bottom .sf-viewport, .sf-t4.sf-slide .sf-nav-on-top .sf-viewport, .sf-t4.sf-slide .sf-nav-on-bottom .sf-viewport, .sf-t4.sf-none .sf-nav-on-top .sf-viewport, .sf-t4.sf-none .sf-nav-on-bottom .sf-viewport {
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada; }
  .sf-t4 .sf-nav-on-left {
    border-left: 1px solid #dadada;
    padding-left: 20px; }
    .sf-t4 .sf-nav-on-left .sf-controls {
      padding: 0 20px; }
  .sf-t4 .sf-nav-on-right {
    border-right: 1px solid #dadada;
    padding-right: 20px; }
    .sf-t4 .sf-nav-on-right .sf-controls {
      padding: 0 20px; }
  .sf-t4 .sf-nav-wrap.sf-nav-left li, .sf-t4 .sf-nav-wrap.sf-nav-right li {
    padding: 0px 20px 0 40px;
    height: 30px;
    margin-bottom: 30px;
    line-height: 30px; }
  .sf-t4 .sf-nav-wrap.sf-nav-right li {
    margin-left: 20px; }
  .sf-t4 .sf-nav-right li.sf-li-nonumber, .sf-t4 .sf-nav-left li.sf-li-nonumber {
    padding-left: 0;
    margin-bottom: 10px; }
  .sf-t4 .sf-nav-number {
    transition: all 200ms;
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    line-height: 30px;
    font-weight: 700;
    height: 30px;
    width: 30px;
    border: 1px solid #a3a3a3;
    text-align: center;
    color: #7a7a7a; }
  .sf-t4 li.sf-nav-unlink {
    color: #929292; }
    .sf-t4 li.sf-nav-unlink .sf-nav-number {
      border-color: #c1c1c1;
      color: #929292; }
  .sf-t4.sf-rtl .sf-nav-number {
    left: auto;
    right: 0; }
  .sf-t4.sf-rtl .sf-nav-left li {
    padding-left: 0px;
    padding-right: 45px;
    margin-right: 20px;
    text-align: right; }
    .sf-t4.sf-rtl .sf-nav-left li.sf-li-nonumber {
      padding-right: 0; }
  .sf-t4.sf-rtl .sf-nav-right li {
    padding-right: 45px;
    padding-left: 0px;
    margin-right: 20px;
    text-align: right; }
    .sf-t4.sf-rtl .sf-nav-right li.sf-li-nonumber {
      padding-right: 0; }
  .sf-t4 .sf-nav li.sf-nav-step-disabled .sf-nav-number {
    background: #f3f3f3; }
  .sf-t4 .sf-nav li.sf-nav-mark-step:not(.sf-nav-step-disabled) .sf-nav-number {
    background: #F22B4A;
    color: #FFF;
    border-color: #F22B4A; }
  .sf-t4 .sf-nav li.sf-active .sf-nav-number {
    background: #b21948;
    color: #FFF;
    border-color: #b21948; }
  .sf-t4 .sf-li-number .sf-nav-subtext {
    padding-left: 0px; }
  .sf-t4.sf-wizard .sf-btn, .sf-t4 .sf-content .nocsript-sf-btn {
    height: 40px;
    line-height: 40px;
    padding: 0 40px;
    border: 1px solid #a3a3a3;
    color: #7a7a7a;
    text-decoration: none;
    background: #FFF; }
  .sf-t4.sf-wizard.sf-nomob .sf-btn:not(.sf-btn-disabled):hover, .sf-t4.sf-wizard.sf-mob .sf-btn:not(.sf-btn-disabled):active {
    background: #b21948;
    color: #FFF;
    border-color: #b21948; }
  .sf-t4.sf-wizard .sf-btn.sf-btn-disabled {
    background: #f3f3f3; }
  .sf-t4.sf-wizard .sf-nav-on-bottom {
    padding-bottom: 10px; }
    .sf-t4.sf-wizard .sf-nav-on-bottom .sf-controls {
      margin-bottom: 20px; }
  .sf-t4.sf-3d-cube .sf-step {
    background: #FFF; }
  .sf-t4.sf-3d-cube .sf-nav-on-top .sf-step, .sf-t4.sf-3d-cube .sf-nav-on-bottom .sf-step {
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada; }
  .sf-t4 .spinner > div {
    background: #b21948; }

@media all and (max-width: 992px) {
  .sf-t4 .sf-nav-smmob.sf-nav-top, .sf-t4 .sf-nav-smmob.sf-nav-bottom {
    height: 55px; }
  .sf-t4 .sf-nav-smmob .sf-nav li {
    padding: 0 50px 0 0;
    width: 30px; }
  .sf-t4 .sf-nav-smmob .sf-nav li .sf-nav-subtext {
    display: none; }
  .sf-t4 .sf-nav-smmob .sf-nav li.sf-li-nonumber .sf-nav-number {
    display: block; }
  .sf-t4 .sf-nav-smmob .sf-nav-left .sf-nav li {
    margin-right: 50px;
    padding: 0; }
  .sf-t4.sf-nav-wrap.sf-nav-right li {
    padding: 0; }
  .sf-t4 .sf-nav-wrap.sf-nav-left li, .sf-t4.sf-nav-wrap.sf-nav-right li {
    margin-bottom: 10px; }
  .sf-t4 .sf-nav-left .sf-nav li {
    margin-right: 0px; }
  .sf-t4.sf-rtl .sf-nav-left .sf-nav li {
    padding-right: 40px;
    padding-left: 0px;
    margin-right: 15px; }
    .sf-t4.sf-rtl .sf-nav-left .sf-nav li.sf-li-nonumber {
      padding-right: 15px; }
  .sf-t4.sf-rtl .sf-nav-right .sf-nav li {
    margin-left: 0px;
    padding-left: 15px;
    padding-right: 50px; }
    .sf-t4.sf-rtl .sf-nav-right .sf-nav li.sf-li-nonumber {
      padding-right: 0px; }
  .sf-t4.sf-rtl .sf-nav-smmob.sf-nav-right .sf-nav li {
    padding-left: 0px; }
    .sf-t4.sf-rtl .sf-nav-smmob.sf-nav-right .sf-nav li.sf-li-nonumber {
      padding-left: 50px; }
  .sf-t4.sf-rtl .sf-nav-smmob.sf-nav-left .sf-nav li {
    padding-left: 30px;
    padding-right: 0px; }
    .sf-t4.sf-rtl .sf-nav-smmob.sf-nav-left .sf-nav li.sf-li-nonumber {
      padding-right: 0px; }
  .sf-t4.sf-wizard .sf-btn {
    padding: 0 10px; } }
.sf-t5 {
  font-family: sans-serif; }
  .sf-t5 .sf-nav-bottom {
    margin-top: 23px;
    padding-top: 12px; }
  .sf-t5 .sf-nav-top, .sf-t5 .sf-nav-bottom {
    height: 150px; }
  .sf-t5.sf-nonumbers .sf-nav-top, .sf-t5.sf-nonumbers .sf-nav-bottom {
    height: 70px; }
  .sf-t5 .sf-nonumbers .sf-nav-bottom {
    padding-top: 0; }
  .sf-t5 .sf-nav-top {
    padding-bottom: 20px; }
  .sf-t5 .sf-nav {
    margin-bottom: 25px;
    top: 12px; }
  .sf-t5 .sf-nav li {
    font-size: 13px;
    color: #a3aab0;
    height: 60px;
    line-height: 40px;
    text-align: center;
    margin-right: 40px;
    padding: 75px 0 0 0;
    min-width: 95px;
    font-weight: 700; }
  .sf-t5.sf-rtl .sf-nav li {
    margin-right: 0;
    margin-left: 40px; }
  .sf-t5 .sf-nav-link .sf-nav-subtext {
    color: #787878; }
  .sf-t5 .sf-nav li.sf-active, .sf-t5 .sf-nomob .sf-nav li.sf-nav-link:not(.sf-nav-step-disabled):hover,
  .sf-t5 .sf-mob .sf-nav li.sf-nav-link:not(.sf-nav-step-disabled):active {
    color: #40464b; }
    .sf-t5 .sf-nav li.sf-active .sf-nav-subtext, .sf-t5 .sf-nomob .sf-nav li.sf-nav-link:not(.sf-nav-step-disabled):hover .sf-nav-subtext,
    .sf-t5 .sf-mob .sf-nav li.sf-nav-link:not(.sf-nav-step-disabled):active .sf-nav-subtext {
      color: #40464b; }
  .sf-t5 .sf-nav-top li.sf-active {
    margin-top: 0px; }
  .sf-t5 .sf-nav-number {
    position: absolute;
    margin-left: -35px;
    top: 0;
    left: 50%;
    font-size: 30px;
    line-height: 66px;
    font-weight: 800;
    height: 70px;
    width: 70px;
    border-radius: 70px;
    border: 1px solid #cfcfcf;
    text-align: center;
    color: #929292; }
  .sf-t5 .sf-nav-number:before {
    content: "";
    display: block;
    width: 90px;
    height: 90px;
    top: -11px;
    left: -11px;
    border-radius: 90px;
    position: absolute;
    background: #dedede;
    z-index: -3;
    box-shadow: 0 3px 4px #c7c6c5 inset; }
  .sf-t5 .sf-nav .sf-nav-number:after {
    content: "";
    display: block;
    width: 135px;
    height: 15px;
    top: 27px;
    left: 75px;
    position: absolute;
    background: #dedede;
    z-index: -2; }
  .sf-t5 .sf-nav li:last-child .sf-nav-number:after {
    display: none; }
  .sf-t5.sf-rtl .sf-nav-number:after {
    left: auto;
    right: 75px; }
  .sf-t5.sf-rtl .sf-nav-left .sf-nav li {
    padding-right: 15px; }
  .sf-t5 .sf-nav li.sf-active .sf-nav-number, .sf-t5 .sf-nav li.sf-nav-mark-step .sf-nav-number {
    border-color: transparent; }
  .sf-t5 .sf-nav li .sf-nav-number-inner {
    position: absolute;
    top: 9px;
    left: 9px;
    margin-left: auto;
    line-height: 50px;
    display: block;
    height: 50px;
    width: 50px;
    border-radius: 60px;
    color: #a3aab0; }
  .sf-t5 .sf-nav li .sf-nav-number-inner:before {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    content: "";
    background: #FFF;
    position: absolute;
    z-index: -1;
    margin-left: -25px;
    top: 0px;
    left: 50%;
    height: 50px;
    width: 50px;
    border-radius: 70px;
    border: 1px solid #cfcfcf; }
  .sf-t5 .sf-nav li .sf-nav-number-inner:after {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    content: "";
    position: absolute;
    z-index: -1;
    top: -10px;
    left: 50%;
    margin-left: -35px;
    line-height: 50px;
    display: block;
    height: 70px;
    width: 70px;
    border-radius: 70px;
    background: #ffffff;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 39%, #ebebeb 98%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(39%, #ffffff), color-stop(98%, #ebebeb));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 39%, #ebebeb 98%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffffff 0%, #ffffff 39%, #ebebeb 98%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffffff 0%, #ffffff 39%, #ebebeb 98%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 39%, #ebebeb 98%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ebebeb',GradientType=0 );
    /* IE6-9 */ }
  .sf-t5 .sf-nav li.sf-nav-unlink .sf-nav-subtext {
    color: #a3aab0; }
  .sf-t5 .sf-nav li.sf-nav-unlink .sf-nav-number-inner:after {
    background: #F4F3F3;
    /* Old browsers */
    background: -moz-linear-gradient(top, #F4F3F3 0%, #F4F3F3 39%, #E2E2E2 98%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F4F3F3), color-stop(39%, #F4F3F3), color-stop(98%, #E2E2E2));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #F4F3F3 0%, #F4F3F3 39%, #E2E2E2 98%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #F4F3F3 0%, #F4F3F3 39%, #E2E2E2 98%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #F4F3F3 0%, #F4F3F3 39%, #E2E2E2 98%);
    /* IE10+ */
    background: linear-gradient(to bottom, #F4F3F3 0%, #F4F3F3 39%, #E2E2E2 98%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F4F3F3', endColorstr='$t5_step_in_end_unlink',GradientType=0 );
    /* IE6-9 */ }
  .sf-t5 .sf-nav li.sf-active .sf-nav-number-inner, .sf-t5 .sf-nav li.sf-nav-mark-step .sf-nav-number-inner {
    color: #40464b; }
  .sf-t5 .sf-nav li.sf-nav-mark-step .sf-nav-number-inner {
    color: #61676b; }
  .sf-t5 .sf-nav li.sf-active .sf-nav-number-inner:before, .sf-t5 .sf-nav li.sf-nav-mark-step .sf-nav-number-inner:before,
  .sf-t5 .sf-nav li.sf-nav-step-disabled .sf-nav-number-inner:before {
    content: "";
    background: #13d9b7;
    border: 1px solid #cfcfcf;
    box-shadow: 0 3px 4px #00b89b inset;
    margin-left: -37px;
    top: -12px;
    left: 50%;
    height: 74px;
    width: 74px; }
  .sf-t5 .sf-nav li.sf-nav-step-disabled .sf-nav-number {
    border: 1px solid transparent; }
  .sf-t5 .sf-nav li.sf-nav-step-disabled .sf-nav-number-inner:before {
    background: #dedede;
    box-shadow: none;
    border: 1px solid transparent; }
  .sf-t5 .sf-nav li.sf-nav-mark-step:not(.sf-nav-step-disabled) .sf-nav-number-inner:before {
    background: #40D8A3;
    box-shadow: 0 3px 4px #04b497 inset; }
  .sf-t5 .sf-nav li.sf-active .sf-nav-number-inner:after {
    background: #f4f4f4;
    color: #40464b;
    box-shadow: 0 3px 4px #dbf3ef inset;
    top: 0px;
    left: 50%;
    margin-left: -25px;
    line-height: 50px;
    height: 50px;
    width: 50px; }
  .sf-t5 .sf-nav li.sf-nav-step-disabled .sf-nav-number-inner:after {
    opacity: 0; }
  .sf-t5 .sf-nav li.sf-nav-mark-step .sf-nav-number-inner:after {
    top: 0px;
    left: 50%;
    margin-left: -25px;
    line-height: 50px;
    height: 50px;
    width: 50px; }
  .sf-t5 .sf-li-number .sf-nav-subtext {
    padding-left: 0px; }
  .sf-t5 .sf-nav-top li.sf-li-nonumber, .sf-t5 .sf-nav-bottom li.sf-li-nonumber {
    height: 30px;
    padding: 0; }
  .sf-t5 .sf-nav-right .sf-nav {
    margin-left: 20px; }
  .sf-t5 .sf-nav-left li, .sf-t5 .sf-nav-right li {
    height: 100px;
    padding-top: 25px;
    text-align: left;
    margin-bottom: 0; }
  .sf-t5 .sf-nav-left li .sf-nav-subtext, .sf-t5 .sf-nav-right li .sf-nav-subtext {
    padding-left: 117px; }
  .sf-t5 .sf-nav-left li .sf-nav-number, .sf-t5 .sf-nav-right li .sf-nav-number {
    left: 50px;
    top: 12px; }
  .sf-t5 .sf-nav-left .sf-nav-number:after, .sf-t5 .sf-nav-right .sf-nav-number:after {
    width: 15px;
    height: 60px;
    top: 70px;
    left: 27px;
    position: absolute;
    background: #dedede;
    z-index: -2; }
  .sf-t5.sf-rtl .sf-nav-left .sf-nav-number:after, .sf-t5.sf-rtl .sf-nav-right .sf-nav-number:after {
    left: auto;
    right: 27px; }
  .sf-t5 .sf-nav-left li.sf-li-nonumber, .sf-t5 .sf-nav-right li.sf-li-nonumber {
    height: 30px;
    line-height: 30px;
    padding: 0; }
  .sf-t5 .sf-nav-left li.sf-li-nonumber .sf-nav-subtext, .sf-t5 .sf-nav-right li.sf-li-nonumber .sf-nav-subtext {
    padding-left: 0; }
  .sf-t5.sf-wizard .sf-btn, .sf-t5 .sf-content .nocsript-sf-btn {
    height: 40px;
    line-height: 40px;
    padding: 0 40px;
    border: 1px solid #c7c6c5;
    border-radius: 5px;
    color: #40464b;
    font-size: 13px;
    font-weight: 700;
    text-decoration: none;
    background: #efefef;
    /* Old browsers */
    background: -moz-linear-gradient(top, #efefef 0%, #dadada 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #efefef), color-stop(100%, #dadada));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #efefef 0%, #dadada 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #efefef 0%, #dadada 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #efefef 0%, #dadada 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #efefef 0%, #dadada 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$t5_btn_bg_start', endColorstr='$t5_btn_bg_end',GradientType=0 );
    /* IE6-9 */ }
    .sf-t5.sf-wizard .sf-btn.sf-btn-disabled, .sf-t5 .sf-content .nocsript-sf-btn.sf-btn-disabled {
      background: #e8e8e8;
      color: #a1a1a1; }
  .sf-t5.sf-wizard.sf-nomob .sf-btn:not(.sf-btn-disabled):hover, .sf-t5.sf-wizard.sf-mob .sf-btn:not(.sf-btn-disabled):active {
    background: #efefef; }
  .sf-t5.sf-fade .sf-viewport-out, .sf-t5.sf-none .sf-viewport-out, .sf-t5.sf-slide .sf-viewport-out {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #c7c6c5;
    box-shadow: 0px 0px 4px #d1cfcf inset, 0 0 0 10px #e4e4e4 inset; }
  .sf-t5.sf-fade .sf-viewport, .sf-t5.sf-none .sf-viewport, .sf-t5.sf-slide .sf-viewport {
    background: transparent;
    padding: 20px; }
  .sf-t5 .sf-viewport {
    margin-bottom: 0; }
  .sf-t5 .sf-controls {
    margin-top: 20px; }
  .sf-t5 .sf-nav-on-bottom .sf-controls {
    margin-bottom: 20px; }
  .sf-t5.sf-3d-cube.sf-wizard > .sf-content {
    padding: 0px;
    border: none;
    box-shadow: none; }
    .sf-t5.sf-3d-cube.sf-wizard > .sf-content .sf-step {
      padding: 45px;
      border-radius: 5px;
      border: 1px solid #c7c6c5;
      box-shadow: 0px 0px 4px #d1cfcf inset, 0 0 0 10px #e4e4e4 inset; }
  .sf-t5.sf-wizard > .sf-content:before {
    content: "";
    display: block; }
  .sf-t5.sf-3d-cube .sf-step {
    background: #FFF; }
  .sf-t5 .spinner > div {
    background: #13d9b7; }
  .sf-t5 .sf-nav-left .spinner, .sf-t5 .sf-nav-right .spinner {
    margin-top: 0px;
    float: right; }

@media all and (max-width: 1200px) {
  .sf-t5 .sf-nav li {
    margin-right: 20px; }
    .sf-t5 .sf-nav li .sf-nav-number:after {
      width: 115px; } }
@media all and (max-width: 992px) {
  .sf-t5 .sf-nav-smmob.sf-nav-top, .sf-t5 .sf-nav-smmob.sf-nav-bottom {
    height: 115px; }
  .sf-t5 .sf-nav-smmob.sf-nav-right .sf-nav {
    margin-left: 0px; }
  .sf-t5 .sf-nav-smmob.sf-nav-left li.sf-li-nonumber, .sf-t5 .sf-nav-smmob.sf-nav-right li.sf-li-nonumber {
    height: 90px; }
  .sf-t5 .sf-nav-smmob.sf-nav-top .sf-nav-number:after, .sf-t5 .sf-nav-smmob.sf-nav-bottom .sf-nav-number:after {
    width: 50px; }
  .sf-t5 .sf-nav-smmob li span {
    display: none; }
  .sf-t5 .sf-nav-smmob li.sf-active:after {
    border: none; }
  .sf-t5 .sf-nav-smmob.sf-nav-top .sf-nav-step.sf-active:after {
    display: none; }
  .sf-t5 .sf-nav-smmob li.sf-li-nonumber .sf-nav-number {
    display: block; }
  .sf-t5.sf-rtl .sf-nav-left .sf-nav li, .sf-t5:not(.sf-rtl) .sf-nav-left .sf-nav li {
    margin-right: 10px;
    margin-left: 0px; }
  .sf-t5.sf-rtl .sf-nav-right .sf-nav li, .sf-t5:not(.sf-rtl) .sf-nav-right .sf-nav li {
    margin-left: 10px;
    margin-right: 0; }
  .sf-t5.sf-rtl:not(.sf-rtl) .sf-nav-right .sf-nav li, .sf-t5:not(.sf-rtl):not(.sf-rtl) .sf-nav-right .sf-nav li {
    margin-right: 25px; }
  .sf-t5.sf-rtl .sf-nav-left .sf-nav li.sf-li-nonumber {
    text-align: right; }
  .sf-t5.sf-nonumbers .sf-nav-smmob li.sf-nav-step {
    height: 60px; }
  .sf-t5.sf-nonumbers .sf-nav-smmob.sf-nav-top, .sf-t5.sf-nonumbers .sf-nav-smmob.sf-nav-bottom {
    height: 115px; }
  .sf-t5.sf-nonumbers .sf-nav-smmob.sf-nav-left li, .sf-t5.sf-nonumbers .sf-nav-smmob.sf-nav-right li {
    height: 100px; }
  .sf-t5.sf-wizard .sf-btn {
    padding: 0 10px; } }

/*# sourceMappingURL=step-form-wizard-all.css.map */
